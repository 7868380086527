<template>
  <div class="profitListView">
    <van-sticky>
      <van-nav-bar :title="$t('购买记录')" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" loading-text="..." @load="onLoad">
        <van-cell v-for="(item, key) in list" :key="key" class="xiax_item">
            <div class="left">
              <span class="desc">{{$t("预计收益")}}:{{ item.money*item.profit*0.01 }}</span>
              <span class="money">{{ item.addtime }}</span>
            </div>
            <div class="right">
              <span class="time">{{$t("金额")}}:{{ item.money }}</span>
              <span class="status">{{ item.title }}({{$t("收益中")}})</span>
            </div>
        </van-cell>
    </van-list>
  </div>
</template>

<script>
import { profitListView } from '@/api/users';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //获取充值记录
    getLists() {
      profitListView({ page: this.page, pageSize: this.pageSize })
        .then((response) => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {});
    },
    onLoad() {
      this.getLists();
    },
  },
};
</script>
<style lang="stylus">
.profitListView
  .van-list
    margin-top: 0.3rem
    .xiax_item
      background-color: #151d31
      margin-bottom: 0.15rem
      line-height: 0.6rem
      padding: 0.15rem 0.4rem
      span
        display: block
    .van-cell__value
      display: flex
      color: #c0c4cc
      align-items: center
      .icon
        color: #fff
        width: 0.75rem
        height: 0.75rem
        line-height: 0.75rem
        text-align: center
        border-radius: 100%
        font-size: 0.325rem
        margin-right: 0.15rem
        flex: none
        overflow: hidden
      .tag0
        background-color: #dd6161
      .tag1
        background-color: #4040ca
      .left
        font-size: 0.32rem
        padding: 6px
        .desc
          color: $specColor
          font-weight: 500
        .money
          font-size: 0.32rem
      .right
        flex: 1
        font-size: 0.32rem
        padding: 6px
        text-align: right
        .time
          font-weight: 450
          font-size: 0.32rem;
</style>
